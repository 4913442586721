import React from "react";
import { Image } from "react-bootstrap";
import Border from "../../assets/images/Border.png";
function DrawnBorder() {
  return (
    <Image src={Border} loading="lazy" alt="border" className="drawn-border" />
  );
}

export default DrawnBorder;
