import { Toast } from "../components/common/toast/Toast";
import { http } from "./httpService";
const apiEndPoint = process.env.REACT_APP_BASE_URL;
export const getCampaignsPage = async (t) => {
  try {
    return await http.get(apiEndPoint + "/getPage/3");
  } catch (ex) {
    return Toast("error", t("error-occured"));
  }
};
export const getCampaigns = async (t) => {
  try {
    return await http.get(apiEndPoint + "/getCampaigns");
  } catch (ex) {
    return Toast("error", t("error-occured"));
  }
};
