import React from "react";
import LightBlueContainer from "../common/light-blue-container/LightBlueContainer";
import { Col, Row } from "react-bootstrap";
import DomParser from "../common/domParser/DomParser";
import { useSelector } from "react-redux";
import ServerImage from "../common/server-images/ServerImage";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes/Routes";

function CampaignsList({ campaignsList }) {
  const isArabic = useSelector((state) => state.language.isArabic);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleNavigateToCampaign = (id) => {
    navigate(routes.campaignsRoute + `/${id}`);
    window.scrollTo(0, 0);
  };
  return campaignsList.map((c, i) => {
    return (
      <Row key={c.id} className="campaigns-list">
        <Col xs={12} sm={12}>
          <LightBlueContainer>
            <Col xs={12} sm={12} className="campaigns-title">
              <div className="campaign-num">
                {i < 10 ? "0" + (i + 1) : i + 1}
              </div>
              <DomParser htmlResponse={!isArabic ? c.title : c.title_ar} />
            </Col>
          </LightBlueContainer>
          <Col xs={12} sm={12} md={12} className="campaigns-text-img">
            {c.image && <ServerImage src={c.image} />}
            <DomParser htmlResponse={!isArabic ? c.text : c.text_ar} />
            <div
              className="read-more"
              onClick={() => handleNavigateToCampaign(c.id)}
            >
              {t("read-more")}
            </div>
          </Col>
        </Col>
      </Row>
    );
  });
}

export default CampaignsList;
