import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toggleShowModal } from "../../../redux/actions/modalActions";

function ReadMore({ overFlowIndex, i, SliderBody }) {
  const dispatch = useDispatch();
  const handleShowModal = () => {
    dispatch(toggleShowModal(true, SliderBody));
  };
  const { t } = useTranslation();

  return overFlowIndex.includes(i) ? (
    <span className="slide-read-more">
      <span onClick={handleShowModal}>{t("read-more")}</span>
    </span>
  ) : (
    <></>
  );
}

export default ReadMore;
