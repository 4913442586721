import React from "react";
import { Col } from "react-bootstrap";
import LightBlueContainer from "../common/light-blue-container/LightBlueContainer";
import { getDataByApiName } from "../../utlis/getDataByApiName";
import ServerImage from "../common/server-images/ServerImage";
import { useSelector } from "react-redux";
import DomParser from "../common/domParser/DomParser";
function HighFiveSection({ homePage }) {
  const isArabic = useSelector((state) => state.language.isArabic);
  const highFiveImage = getDataByApiName(
    homePage[0],
    "images",
    "positiveChange"
  );
  const highFiveText = getDataByApiName(homePage[0], "texts", "positiveChange");

  return (
    <LightBlueContainer>
      <Col sm={12} lg={7}>
        {highFiveText.active ? (
          <p className="title mb-5">
            <DomParser
              className="title border"
              htmlResponse={
                isArabic ? highFiveText.content_ar : highFiveText.content
              }
              id="approach"
            />
          </p>
        ) : (
          <></>
        )}
      </Col>
      <Col
        md={12}
        lg={5}
        className="home_high-five-section-icon-container mt-5"
      >
        {highFiveImage.active && highFiveImage.image ? (
          <ServerImage
            src={highFiveImage.image}
            alt={isArabic ? highFiveImage.alt_ar : highFiveImage.alt}
          />
        ) : (
          <></>
        )}
      </Col>
    </LightBlueContainer>
  );
}

export default HighFiveSection;
