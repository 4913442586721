import React from "react";
import { useSelector } from "react-redux";
import ServerImage from "../server-images/ServerImage";
import DomParser from "../domParser/DomParser";

function SliderBody({ s }) {
  const isArabic = useSelector((state) => state.language.isArabic);
  return (
    <>
      {s.image && (
        <div style={{ textAlign: isArabic ? "right" : "left" }}>
          <ServerImage src={s.image} alt="slide" />
        </div>
      )}
      <p
        className="slide-title"
        style={{ textAlign: !isArabic ? "left" : "right" }}
      >
        <span>{isArabic ? s.title_ar : s.title}</span>
      </p>
      <div className="slide-desc">
        <DomParser htmlResponse={isArabic ? s.text_ar : s.text} />
      </div>
      ;
    </>
  );
}

export default SliderBody;
