import { Toast } from "../components/common/toast/Toast";
import { http } from "./httpService";
const apiEndPoint = process.env.REACT_APP_BASE_URL;
const apiContactEndPoint = process.env.REACT_APP_CONTACT_BASE_URL;
export const getDaringHubPage = async (t) => {
  try {
    return await http.get(apiEndPoint + "/getPage/4");
  } catch (ex) {
    return Toast("error", t("error-occured"));
  }
};
export const contactUsService = async ({ phone, name, message, email }, t) => {
  try {
    return await http.get(apiContactEndPoint + "/contactMessage/newMessage", {
      params: {
        phone,
        name,
        message,
        email,
      },
    });
  } catch (ex) {
    return Toast("error", t("error-occured"));
  }
};
