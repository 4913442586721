import React from "react";
import { Image, Row } from "react-bootstrap";
import ArrowDown from "../../../assets/images/agency-arrow.png";
function LightBlueContainer({ children, showArrow = false }) {
  return (
    <Row className="light-blue-container justify-content-center align-items-center mx-1 my-5 px-4">
      {children}
      {showArrow && (
        <Image src={ArrowDown} alt="arrow" className="light-blue-arrow" />
      )}
    </Row>
  );
}

export default LightBlueContainer;
