import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import FormElement from "../../components/common/form/FormElement";
import { useTranslation } from "react-i18next";
import CommonButton from "../../components/common/button/CommonButton";
import { Toast } from "../../components/common/toast/Toast";
import { contactUsService } from "../../services/daringHubService";
import { SUCCESS } from "../../services/httpService";

function ContactForm() {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    email: "",
    name: "",
    phone: "",
    message: "",
  });
  const { email, phone, name, message } = data;
  const handleSendMessage = async () => {
    if (!email || !name || !phone || !message.length) {
      return Toast("info", t("fill_all"));
    }
    if (!email.match(emailPattern)) {
      return Toast("info", t("email-validation"));
    }
    setIsLoading(true);
    const { data: contactUs } = await contactUsService(data, t);
    if (contactUs.AZSVR === SUCCESS) {
      setIsLoading(false);
      Toast("success", t("sent-successfully"));
      setData({ email: "", name: "", phone: "", message: "" });
    } else return Toast("error", t("error-occured"));
  };
  return (
    <Col className="form py-3 my-3" xs={12} sm={12} md={10} lg={8}>
      <Row className="justify-content-center my-3">
        <h3>{t("contact")}</h3>
      </Row>
      <Form>
        <Row className="justify-content-center mb-2">
          <Col>
            <FormElement
              data={data}
              setData={setData}
              value={data.name}
              name="name"
              placeholder={t("name")}
            />
          </Col>
        </Row>
        <Row className="justify-content-center mb-2">
          <Col>
            <FormElement
              data={data}
              setData={setData}
              value={data.email}
              name="email"
              placeholder={t("email")}
            />
          </Col>
        </Row>
        <Row className="justify-content-center mb-2">
          <Col>
            <FormElement
              data={data}
              setData={setData}
              value={data.phone}
              name="phone"
              type="tel"
              placeholder={t("phone")}
            />
          </Col>
        </Row>
        <Row className="justify-content-center mb-2">
          <Col>
            <FormElement
              data={data}
              setData={setData}
              value={data.message}
              name="message"
              element="textarea"
              placeholder={t("message")}
            />
          </Col>
        </Row>

        <Row className="justify-content-center mt-3">
          <Col>
            <CommonButton
              label={t("send-message")}
              disabled={isLoading}
              onClick={handleSendMessage}
            />
          </Col>
        </Row>
      </Form>
    </Col>
  );
}

export default ContactForm;
