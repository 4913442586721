import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoCloseSharp } from "react-icons/io5";

import { toggleShowModal } from "../../../redux/actions/modalActions";

function Modal() {
  const show = useSelector((state) => state.modal.show);
  const component = useSelector((state) => state.modal.component);
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(toggleShowModal(false, <></>));
  };
  if (show)
    return (
      <>
        <div className="modal-conatiner" onClick={handleCloseModal} />
        <div className="common-modal">
          <div className="common-modal_title">
            <IoCloseSharp onClick={handleCloseModal} />
          </div>
          <div>{component}</div>
        </div>
      </>
    );
  return <></>;
}

export default Modal;
