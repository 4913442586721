import { Toast } from "../components/common/toast/Toast";
import { http } from "./httpService";
const apiEndPoint = process.env.REACT_APP_BASE_URL;
export const getHomePage = async (t) => {
  try {
    return await http.get(apiEndPoint + "/getPage/1");
  } catch (ex) {
    return Toast("error", t("error-occured"));
  }
};
export const getTeamCards = async (t) => {
  try {
    return await http.get(apiEndPoint + "/getTeamCards");
  } catch (ex) {
    return Toast("error", t("error-occured"));
  }
};
export const getLogos = async (t) => {
  try {
    return http.get(apiEndPoint + "/getLogos");
  } catch (ex) {
    return Toast("error", t("error-occured"));
  }
};
