import React from "react";
import { CircleLoader } from "react-spinners";
function LoadingIcon() {
  return (
    <div className="loading-section">
      <CircleLoader size="50px" />
    </div>
  );
}

export default LoadingIcon;
