import React from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";

function TextArea({ label, value, ...rest }) {
  const isArabic = useSelector((state) => state.language.isArabic);
  return (
    <Form.Group
      className="mb-3"
      controlId="exampleForm.ControlTextarea1"
      style={isArabic ? { direction: "rtl" } : {}}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control as="textarea" rows={5} {...rest} value={value} />
    </Form.Group>
  );
}

export default TextArea;
