import React from "react";

import { getDataByApiName } from "../../utlis/getDataByApiName";
import FirstPageSection from "../common/firstPageSection/FirstPageSection";
import { Col, Row } from "react-bootstrap";
import CommonButton from "../common/button/CommonButton";
import Arrow from "../arrows/Arrow";
import { routes } from "../../routes/Routes";
import { useTranslation } from "react-i18next";
function HomeFirstSection({ homePage, ...rest }) {
  const firstSectionImage = getDataByApiName(
    homePage[0],
    "images",
    "landingAboutUsImage"
  );
  const firstSectionTitle = getDataByApiName(
    homePage[0],
    "texts",
    "landingAboutUsTitle"
  );
  const firstSectionInfo = getDataByApiName(
    homePage[0],
    "texts",
    "landingAboutUsInfo"
  );
  const { t } = useTranslation();
  return (
    <FirstPageSection
      firstSectionInfo={firstSectionInfo}
      firstSectionTitle={firstSectionTitle}
      sectionImage={firstSectionImage}
      isHome={true}
      {...rest}
    >
      <Row className="justify-content-center">
        <Col sm={6} className="my-2">
          <CommonButton
            href={routes.iDareEventWebsite}
            isLink={true}
            label={t("join-us")}
            onClick={() => {
              window.open(routes.iDareEventWebsite, "_blank");
            }}
          />
        </Col>
        <Col sm={6} className="my-2">
          <CommonButton
            onClick={() => {
              window.open(routes.blogRoute, "_blank");
            }}
            label={t("blog")}
            variant="secondary"
            icon={<Arrow dir="right" />}
          />
        </Col>
      </Row>
    </FirstPageSection>
  );
}

export default HomeFirstSection;
