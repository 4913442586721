import React from "react";
import { Col, Row } from "react-bootstrap";
import Tabbbed from "../Tabs/Tabs";
import { useSelector } from "react-redux";
import DomParser from "../common/domParser/DomParser";
import { getDataByApiName } from "../../utlis/getDataByApiName";
function ActSection({ homePage }) {
  const isArabic = useSelector((state) => state.language.isArabic);
  const tabsTitle = getDataByApiName(homePage[0], "texts", "tabsTitle");
  return (
    <Row className="justify-content-center act-section mb-4">
      {tabsTitle.active ? (
        <Col
          xs={12}
          sm={10}
          md={9}
          style={{ textAlign: "center" }}
          className={`act-title ${isArabic ? "ar" : ""}`}
        >
          <DomParser
            htmlResponse={isArabic ? tabsTitle.content_ar : tabsTitle.content}
          />
        </Col>
      ) : (
        <></>
      )}

      <Col xs={12} sm={12} className="tabs-container">
        <Tabbbed homePage={homePage} />
      </Col>
    </Row>
  );
}

export default ActSection;
