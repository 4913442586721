import { useSelector } from "react-redux";

const englishText = (
  <>
    <p>Consent to the use of information, photos and videos</p>
    <p>
      For IDare for Sustainable Development (IDare), it is extremely important
      to be able to document our activities and show what we do through real
      people and a real personal commitment. To do this, we need photography and
      video making during the various activities and events that we carry out in
      order to disseminate knowledge, raise awareness and attract support for I
      Dare and the issues that the organization pursues.
    </p>
    <p>
      Due to the legislation in Jordan and the Personal Data Protection Law No.
      (24) of 2023 (effective from 16/3/2024), and in the European Union (GDPR
      Data Protection Law, in force from May 25, 2018), we seek written consent
      for this type of document. Consent means that your personal data,
      including the photos and videos you view, can be used in different
      communication channels and platforms of IDare - Including web pages,
      social media, printed materials, messages to supporters (members, donors,
      volunteers, clients) and campaigns targeting new supporters. Images will
      only be used when the IDare organization is the source, and the material
      may be edited but never distorted. IDare will not sell the
      image/video/information to a third party. The images will be archived in
      the IDare photo archive and in the organization's internal protected
      database.
    </p>
  </>
);

const arabicText = (
  <>
    <p>الموافقة على استخدام البيانات والصور والفيديو</p>
    <p>
      بالنسبة لـ منظمة أنا أتجرأ للتنمية المستدامة (أنا أتجرأ)، من المهم للغاية
      أن نكون قادرين على توثيق أنشطتنا وإظهار ما نقوم به من خلال أشخاص حقيقيين
      والتزام شخصي حقيقي. للقيام بذلك، نحتاج إلى التصوير الفوتوغرافي وعمل فيديو
      أثناء الأنشطة والفعاليات المختلفة التي نقوم بها من أجل نشر المعرفة وزيادة
      الوعي وجذب الدعم لـ أنا أتجرأ والقضايا التي تتابعها المنظمة.
    </p>
    <p>
      نظرًا للتشريعات في كل من الأردن قانون حماية البيانات الشخصية رقم (24) لسنة
      2023 (حيز التنفيذ بدءا من تاريخ 16/3/)2024، و أيضا في الاتحاد الأوروبي
      (GDPR قانون حماية البيانات، قيد التطبيق من 25 مايو 2018)، فإننا نسعى
      للحصول على موافقة كتابية على هذا النوع من الوثائق\البيانات. الموافقة تعني
      أن بياناتك الشخصية بما في ذلك الصور ومقاطع الفيديو التي تشاهدها، يمكن
      استخدامها في قنوات ومنصات اتصال مختلفة لمنظمة أنا أتجرأ - بما في ذلك صفحات
      الويب والوسائط الاجتماعية والمواد المطبوعة والرسائل إلى المؤيدين (الأعضاء
      والجهات المانحة والمتطوعون والعملاء) والحملات التي تستهدف مؤيدين جدد. سيتم
      استخدام الصور فقط عندما تكون منظمة أنا أتجرأ هي المصدر، وقد يتم تحرير
      المواد ولكن لا يتم تشويهها مطلقًا. لن تقوم منظمة أنا أتجرأ ببيع
      الصورة\الفيديو\البيانات إلى طرف ثالث. سيتم أرشفة الصور في أرشيف صور منظمة
      أنا أتجرأ وفي قاعدة بيانات داخلية محمية للمنظمة.
    </p>
  </>
);
export const GetAgreement = () => {
  const isArabic = useSelector((state) => state.language.isArabic);
  return (
    <div style={isArabic ? { textAlign: "right" } : {}}>
      {isArabic ? arabicText : englishText}
    </div>
  );
};
