import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toggleLanguageAction } from "../../redux/actions/languageActions";
import { getLanguage } from "../../assets/translation/language";

function LanguageSwitcher({ setOpenMenu }) {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const switchLng = (lng) => {
    i18n.changeLanguage(lng);
    dispatch(toggleLanguageAction());
  };
  const isActiveLng = (lng) => (getLanguage() === lng ? "active" : "");
  return (
    <div
      className="header_right-side_langauges"
      style={setOpenMenu ? { display: "block", marginTop: "10px" } : {}}
    >
      <span
        className={`langauges__en ${isActiveLng("en")} `}
        onClick={() => switchLng("en")}
      >
        ENG
      </span>
      <span
        className={`langauges__ar ${isActiveLng("ar")}`}
        onClick={() => switchLng("ar")}
      >
        AR
      </span>
    </div>
  );
}

export default LanguageSwitcher;
