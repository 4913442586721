import React from "react";
import { Image } from "react-bootstrap";
import ArrowRight from "../../assets/images/ArrowRight.png";
import ArrowLeft from "../../assets/images/ArrowLeft.png";

function Arrow({ dir }) {
  return (
    <Image
      src={dir === "right" ? ArrowRight : ArrowLeft}
      alt="arrow"
      loading="lazy"
    />
  );
}

export default Arrow;
