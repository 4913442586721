import React, { useEffect, useState } from "react";
import { getCustomCards } from "../../utlis/getCustomCards";
import TabPannels from "./TabPannels";
import TabTitles from "./TabTitles";
import { Row } from "react-bootstrap";
import _ from "lodash";
function Tabs({ homePage }) {
  const [tabs, setTabs] = useState([]);
  useEffect(() => {
    setTabs(getCustomCards(homePage, "tabs"));
  }, [homePage]);
  const [activeTab, setActiveTab] = useState(0);
  const [prevTabs, setPrevTabs] = useState([0]);
  const toggleTabs = (index) => {
    if (index !== prevTabs[prevTabs.length - 1]) {
      setPrevTabs(_.range(index + 1));
    }
    if (index && !prevTabs.includes(index)) {
      setPrevTabs(_.range(index + 1));
    }

    setActiveTab(index);
  };
  return (
    <Row className="mb-3">
      <TabTitles activeTab={activeTab} tabs={tabs} toggleTabs={toggleTabs} />
      <TabPannels tabs={tabs} prevTabs={prevTabs} activeTab={activeTab} />
    </Row>
  );
}

export default Tabs;
