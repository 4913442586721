import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getTeamCards } from "../../services/homeService";
import ServerImage from "../common/server-images/ServerImage";
import DomParser from "../common/domParser/DomParser";
import { getDataByApiName } from "../../utlis/getDataByApiName";

function OurTeam({ homePage }) {
  const [data, setData] = useState([]);
  const isArabic = useSelector((state) => state.language.isArabic);
  const outTeamTitle = getDataByApiName(homePage[0], "texts", "our-team-title");
  useEffect(() => {
    const getTeamInfo = async () => {
      const { data } = await getTeamCards();
      setData(data);
    };
    getTeamInfo();
  }, []);
  return (
    <Row className="justify-content-center">
      <Col sm={12} id="team">
        <Row className="team-section-title justify-conetn-center mb-3">
          <Col>
            <DomParser
              htmlResponse={
                isArabic ? outTeamTitle.content_ar : outTeamTitle.content
              }
              className="border"
            />
          </Col>
        </Row>
      </Col>
      {data.map((t) => {
        return (
          <Col xs={12} sm={5} md={4} lg={3} key={t.id} className="my-3">
            <div className="team-card">
              {t.image && (
                <div>
                  <ServerImage src={t.image} />
                </div>
              )}
              <div className="team-details">
                <p className="team-name">{!isArabic ? t.title : t.title_ar}</p>
                <DomParser htmlResponse={!isArabic ? t.text : t.text_ar} />
              </div>
            </div>
          </Col>
        );
      })}
    </Row>
  );
}

export default OurTeam;
