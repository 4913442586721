import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CommonButton from "../common/button/CommonButton";
import { GetAgreement } from "./agreementTexts";
import { useSelector } from "react-redux";

function Agree() {
  const { t } = useTranslation();
  const key = "isFirstTime";
  const isFirstTime = localStorage.getItem(key);
  const [firstTime, setFirstTime] = useState(!isFirstTime ?? true);
  useEffect(() => {
    localStorage.setItem(key, firstTime);
  }, [isFirstTime, firstTime]);
  const switchFirstTime = () => {
    setFirstTime(false);
  };
  const isArabic = useSelector((state) => state.language.isArabic);
  return (
    <div
      className={`agree ${firstTime ? "opacity" : "no-opacity"}`}
      style={!isArabic ? { left: "10px" } : { right: "10px" }}
    >
      <GetAgreement />
      <CommonButton label={t("isAgree")} onClick={switchFirstTime} />
    </div>
  );
}

export default Agree;
