import React from "react";
import { Col, Image } from "react-bootstrap";
import borderBottom from "../../assets/images/borderBottom.png";
import { useSelector } from "react-redux";

function TabTitles({ tabs, toggleTabs, activeTab }) {
  const isArabic = useSelector((state) => state.language.isArabic);
  return (
    <Col xs={12} sm={12}>
      <ul className="tabs-list">
        {tabs.map((t, index) => {
          return (
            <li
              key={t.id}
              className={`tab ${
                activeTab === index ? "active" : "not-active"
              } `}
              onClick={() => toggleTabs(index)}
            >
              {isArabic ? t.title_ar : t.title}
              <div className="border-container">
                <Image
                  src={borderBottom}
                  alt="border-bottom"
                  className="border-container_bottom"
                />
              </div>
            </li>
          );
        })}
      </ul>
    </Col>
  );
}

export default TabTitles;
