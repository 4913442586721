import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import ServerImage from "../common/server-images/ServerImage";
import { getCustomCards } from "../../utlis/getCustomCards";
import DomParser from "../common/domParser/DomParser";
import { getDataByApiName } from "../../utlis/getDataByApiName";

function YouthSection({ homePage }) {
  const isArabic = useSelector((state) => state.language.isArabic);
  const [data, setData] = useState([]);
  const youthShapingTitle = getDataByApiName(
    homePage[0],
    "texts",
    "youthShapingTitle"
  );
  useEffect(() => {
    setData(getCustomCards(homePage, "youthShaping"));
  }, [homePage]);
  return (
    <Row className="justify-content-center my-5">
      <Col sm={12}>
        {youthShapingTitle.active ? (
          <Row className="youth-section-title justify-content-center mb-3">
            <DomParser
              htmlResponse={
                isArabic
                  ? youthShapingTitle.content_ar
                  : youthShapingTitle.content
              }
              className="border"
            />
          </Row>
        ) : (
          <></>
        )}
        <Row>
          {data.map((d) => {
            return (
              <Col
                xs={12}
                sm={6}
                md={6}
                lg={3}
                key={d.id}
                style={{ textAlign: isArabic ? "right" : "left" }}
              >
                <Row className="youth-data-card mx-2 my-3">
                  {d.image && <ServerImage src={d.image} alt="card-icon" />}
                  <p className="youth-data_title">
                    {isArabic ? d.title_ar : d.title}
                  </p>
                  <span className="youth-data_description">
                    <DomParser htmlResponse={isArabic ? d.text_ar : d.text} />
                  </span>
                </Row>
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
}

export default YouthSection;
