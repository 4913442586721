import React from "react";
import { useTranslation } from "react-i18next";
import { Image, Row } from "react-bootstrap";
import NotFoundImage from "../../assets/images/notfound.svg";
import MainLayout from "../../components/layout/MainLayout";
function NotFound() {
  const { t } = useTranslation();
  return (
    <MainLayout addMargin={false}>
      <Row className="justify-content-center">
        <h3>{t("not-found")}</h3>
      </Row>

      <Row className="justify-content-center my-5">
        <Image
          src={NotFoundImage}
          alt="not-found"
          className="not-found_image"
          fluid
          loading="lazy"
        />
      </Row>
    </MainLayout>
  );
}

export default NotFound;
