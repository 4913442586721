import React, { useEffect } from "react";
import HomeFirstSection from "../../components/home/HomeFirstSection";
import ActSection from "../../components/home/ActSection";
import MainLayout from "../../components/layout/MainLayout";
import HighFiveSection from "../../components/home/HighFiveSection";
import SwiperSection from "../../components/swiper-section/SwiperSection";
import OurTeam from "../../components/team/OurTeam";
import YouthSection from "../../components/youth-section/YouthSection";
import HomeSecondSection from "../../components/home/HomeSecondSection";
import YouthAgency from "../../components/youth-agency/YouthAgency";
import PartnersSection from "../../components/home/PartnersSection";
import { useLocation } from "react-router-dom";

function Home({ homePage, logos }) {
  const location = useLocation();
  useEffect(() => {
    document.title = "About us";
    if (location.hash && document.title === "About us") {
      const el = document.querySelector(location.hash);
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "end",
        });
      }
    }
  }, [location.hash, homePage]);

  return (
    <>
      <MainLayout addMargin={false}>
        <HomeFirstSection homePage={homePage} />
      </MainLayout>
      <div id="partners">
        <PartnersSection logos={logos} />
      </div>
      <MainLayout addMargin={false}>
        <HomeSecondSection homePage={homePage} />
      </MainLayout>
      <MainLayout>
        <HighFiveSection homePage={homePage} />
      </MainLayout>
      <MainLayout>
        <YouthSection homePage={homePage} />
      </MainLayout>
      <MainLayout>
        <YouthAgency homePage={homePage} />
      </MainLayout>
      <MainLayout>
        <SwiperSection homePage={homePage} />
      </MainLayout>
      <MainLayout>
        <ActSection homePage={homePage} />
      </MainLayout>
      <MainLayout>
        <OurTeam homePage={homePage} />
      </MainLayout>
    </>
  );
}

export default Home;
