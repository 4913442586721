import React from "react";
import { Col, Row } from "react-bootstrap";
import TabPannel from "./TabPannel";
import { useSelector } from "react-redux";

function TabPannels({ tabs, prevTabs }) {
  const isArabic = useSelector((state) => state.language.isArabic);
  const handleBackgroundColor = (i) => {
    const backgroundColors = ["#e4e4e4", "#dddddd", "#c3c3c3"];
    if (!backgroundColors[i]) {
      return backgroundColors[tabs.length - i - 1];
    }
    return backgroundColors[i];
  };

  const style = (i) => {
    const scale = 1 - parseFloat(`0.1${i + tabs.length}`);
    const top = 20 * i + "px";
    const transitionDuration = 0.3 + parseFloat(`0.${i}`) + "s";
    const isActiveTabs = prevTabs.includes(i);
    return {
      direction: isArabic ? "rtl" : "ltr",
      opacity: isActiveTabs ? "1" : "0",
      position: "absolute",
      top: !isActiveTabs ? "100%" : top,
      transitionDuration: transitionDuration,
      scale: isActiveTabs ? `${scale}` : "1",
      backgroundColor: handleBackgroundColor(i),
    };
  };

  return (
    <Col xs={12} sm={12} className="tab-pannels" id="tab-pannels">
      {tabs.map((t, i) => {
        return (
          <Row
            style={style(i)}
            id={i}
            key={t.title}
            className={`tab-pannel slide-${i + 1}`}
          >
            <TabPannel tab={t} isArabic={isArabic} />
          </Row>
        );
      })}
    </Col>
  );
}

export default TabPannels;
