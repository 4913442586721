import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FooterLogoSocialMedia from "./FooterLogoSocialMedia";
import _ from "lodash";
import FooterPageLinks from "./FooterPageLinks";
import { routes } from "../../routes/Routes";
import { useSelector } from "react-redux";
import { getDataByName } from "../../utlis/getDataByApiName";
import DomParser from "../common/domParser/DomParser";

function Footer({ homePage, campaignsList, programs }) {
  const { t } = useTranslation();
  const isArabic = useSelector((state) => state.language.isArabic);
  const aboutUslinks = [
    { label: t("who_we_r"), href2: routes.whoWeAre },
    { label: t("partners"), href2: routes.partners },
    { label: t("approach"), href2: routes.approach },
    { label: t("team"), href2: routes.team },
  ];
  const getTitle = (t) => {
    return isArabic
      ? t.title_ar.slice(0, 20) + (t.title_ar.length > 20 ? ".." : "")
      : t.title.slice(0, 20) + (t.title.length > 20 ? ".." : "");
  };
  const getLinks = (arr, route) => {
    if (Array.isArray(arr)) {
      return [
        ...arr.map((c) => {
          return {
            label: getTitle(c),
            path: route + `/${c.id}`,
          };
        }),
      ];
    }
    return [];
  };
  const campaignslinks = getLinks(campaignsList, routes.campaignsRoute);

  const programslinks = getLinks(programs, routes.programRoute);
  const [contactUs, setContactUs] = useState({});
  useEffect(() => {
    if (homePage[0])
      setContactUs(
        getDataByName(homePage[0], "point_categories", "contact-us")
      );
  }, [homePage]);
  const contactUsPoints = _.sortBy(contactUs.points, "sort");
  return (
    <Row
      className={`justify-content-around footer pt-5 ${isArabic ? "ar" : "en"}`}
    >
      <Col xs={12} sm={11} md={11} lg={10}>
        <Row>
          <FooterLogoSocialMedia />
          <Col xs={12} sm={12} md={7}>
            <Row>
              <FooterPageLinks title={t("about-us")} links={aboutUslinks} />
              <FooterPageLinks title={t("campaings")} links={campaignslinks} />
              <FooterPageLinks title={t("programs")} links={programslinks} />
            </Row>
          </Col>
        </Row>
      </Col>
      <Col
        xs={12}
        sm={11}
        md={11}
        lg={11}
        style={{ direction: isArabic ? "rtl" : "ltr" }}
      >
        <Row className="footer_contact-us justify-content-center mt-5 pt-4">
          {contactUsPoints.map((c) => {
            return (
              <Col xs={12} sm={12} md={2} key={c.id}>
                <DomParser htmlResponse={isArabic ? c.text_ar : c.text} />
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
}

export default Footer;
