import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ServerImage from "../common/server-images/ServerImage";
import { getCustomCards } from "../../utlis/getCustomCards";
import { useSelector } from "react-redux";
import DomParser from "../common/domParser/DomParser";
function HomeSecondSection({ homePage }) {
  const isArabic = useSelector((state) => state.language.isArabic);
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(getCustomCards(homePage, "goalsCards"));
  }, [homePage]);
  return (
    <Row className="goals-container">
      {data.map((d) => {
        return (
          <Col xs={11} sm={8} lg={3} key={d.title} className="py-2">
            <Row className="home_second-section-data">
              {d.image && <ServerImage src={d.image} alt="card-icon" />}
              <p className="home_second-section_title">
                {!isArabic ? d.title : d.title_ar}
              </p>
              <DomParser htmlResponse={!isArabic ? d.text : d.text_ar} />
            </Row>
          </Col>
        );
      })}
    </Row>
  );
}

export default HomeSecondSection;
