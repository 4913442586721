import { TOGGLE_SHOW_MODAL } from "../actions/modalActions";
const initialState = {
  show: false,
  component: <></>,
};

export const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SHOW_MODAL:
      return {
        ...state,
        show: action.payload.show,
        component: action.payload.component,
      };
    default:
      return state;
  }
};
