import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { Image, Row } from "react-bootstrap";
import ArrowLeft from "../../../assets/images/ArrowLeft.png";
import ArrowRight from "../../../assets/images/ArrowRight.png";
import SliderBody from "./SliderBody";
import ReadMore from "./ReadMore";
function CommonSwiper({ sliderData, slidesColors, handleBackgroundColor }) {
  const descriptionsRef = useRef([]);
  const [overFlowIndex, setOverFlowIndex] = useState([]);

  useEffect(() => {
    const indices = [];
    const descriptions = document.querySelectorAll(".slide-desc");

    descriptionsRef.current = Array.from(descriptions);

    descriptionsRef.current.forEach((description, index) => {
      const isOverflowing = description.scrollHeight > description.clientHeight;
      if (isOverflowing && !overFlowIndex.includes(index)) {
        indices.push(index);
        setOverFlowIndex([...indices]);
      }
    });
  }, [overFlowIndex]);

  return (
    <>
      <Swiper
        slidesPerView={3}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          480: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
        autoplay={{
          delay: 1000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        modules={[Navigation]}
        loop={true}
      >
        {sliderData.map((s, i) => {
          return (
            <SwiperSlide
              style={{
                backgroundColor: handleBackgroundColor(i),
              }}
              key={slidesColors[i]}
            >
              <ReadMore
                overFlowIndex={overFlowIndex}
                i={i}
                SliderBody={<SliderBody s={s} />}
              />

              <SliderBody s={s} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Row>
        <div className="swiper-buttons-container">
          <div className="swiper-button-prev">
            <Image src={ArrowLeft} alt="arrow-left" loading="lazy" />
          </div>
          <div className="swiper-button-next">
            <Image src={ArrowRight} alt="arrow-right" loading="lazy" />
          </div>
        </div>
      </Row>
    </>
  );
}

export default CommonSwiper;
