import React from "react";
import { Col, Row } from "react-bootstrap";

function MainLayout({ children, className, addMargin = true, id }) {
  return (
    <Row
      className={`${className ? className : ""} justify-content-center ${
        addMargin ? "my-4" : ""
      }`}
      id={id}
    >
      <Col xs={12} sm={11} lg={11}>
        {children}
      </Col>
    </Row>
  );
}

export default MainLayout;
