import React from "react";
import { Col, Row } from "react-bootstrap";
import ServerImage from "../common/server-images/ServerImage";
import DomParser from "../common/domParser/DomParser";

function TabPannel({ tab, isArabic }) {
  return (
    <>
      <Col
        sm={12}
        md={tab.image ? 6 : 12}
        className={isArabic ? "ar-title-desc" : ""}
      >
        <Row className="tab-title-desc p-3">
          <Col sm={12}>
            <p className="tab-title">{isArabic ? tab.title_ar : tab.title}</p>
          </Col>
          <Col sm={12}>
            <p>
              <DomParser htmlResponse={isArabic ? tab.text_ar : tab.text} />
            </p>
          </Col>
        </Row>
      </Col>
      {tab.image && (
        <Col sm={12} md={6}>
          <Row className="justify-content-center my-3">
            <ServerImage src={tab.image} alt="tab media" loading="lazy" />
          </Row>
        </Col>
      )}
    </>
  );
}

export default TabPannel;
