import React from "react";
import { Button } from "react-bootstrap";
function CommonButton({
  label,
  icon,
  isLoading,
  isLink,
  href,
  onClick,
  ...rest
}) {
  return isLink ? (
    <a href={href} target="_blank" rel="noreferrer" className="common-button">
      {label}
    </a>
  ) : (
    <Button {...rest} className="common-button" onClick={onClick}>
      {label}
      {icon}
    </Button>
  );
}
export default CommonButton;
