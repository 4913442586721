import React, { useEffect } from "react";
import { getDataByApiName } from "../../utlis/getDataByApiName";
import FirstPageSection from "../../components/common/firstPageSection/FirstPageSection";
import CampaignsList from "../../components/campaignsList/CampaignsList";
import MainLayout from "../../components/layout/MainLayout";

function Campaigns({ campaignsPage, campaignsList }) {
  const firstSectionImage = getDataByApiName(
    campaignsPage[0],
    "images",
    "campaigns-landing-img"
  );
  const firstSectionTitle = getDataByApiName(
    campaignsPage[0],
    "texts",
    "campaigns-landing-title"
  );
  const firstSectionInfo = getDataByApiName(
    campaignsPage[0],
    "texts",
    "campaigns-landing-info"
  );
  useEffect(() => {
    document.title = "Campaigns";
  }, []);
  return (
    <>
      <MainLayout addMargin={false}>
        <FirstPageSection
          firstSectionInfo={firstSectionInfo}
          firstSectionTitle={firstSectionTitle}
          sectionImage={firstSectionImage}
        />
      </MainLayout>
      <MainLayout addMargin={false}>
        <CampaignsList campaignsList={campaignsList} />
      </MainLayout>
    </>
  );
}

export default Campaigns;
